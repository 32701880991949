<template>
  <div v-if="shops">
    <CCard color="white" text-color="black" class="text-center" body-wrapper>
      <CRow>
        <CCard color="white" text-color="dark" class="text-center col-md-12">
          <CCardBody>
            <CRow>
              <CCol md="12">
                <CRow>
                  <CCol md="3"></CCol>
                  <CCol md="6">
                    <h2 class="font-weight-normal">{{ $t('createCoupon') }}</h2>
                  </CCol>
                  <CCol md="3" class="text-right">
                    <a @click="$router.go(-1)">
                      <CButton>
                        <CIcon name="cil-arrow-thick-from-right" />
                        {{ $t('back') }}
                      </CButton>
                    </a>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>

            <br />
            <CCardBody color="light">
              <CRow>
                <label class="col-sm-3 col-form-label text-left text-dark font-weight-normal">
                  <span>
                    {{ $t('selectCouponType') }}
                    <span class="text-danger font-weight-normal">*</span>
                  </span>
                </label>
                <div class="col-sm-9">
                  <!-- <select class="form-control" v-model="type">
                    <option v-for="opt in getCouponTypeOptions()" :key="opt.value" :value="opt.value">
                      {{ opt.label }}
                    </option>
                  </select> -->
                  <span class="form-control">{{ $t('discountCoupon') }}</span>
                </div>
              </CRow>
            </CCardBody>
            <hr />

            <div v-if="type === '0'">
              <create-by-value></create-by-value>
            </div>
            <div v-else-if="type === '1'">
              <create-by-product></create-by-product>
            </div>
          </CCardBody>
        </CCard>
      </CRow>
    </CCard>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CreateByValue from './CreateByValue'
import CreateByProduct from './CreateByProduct'
import NoShop from '@/containers/NoShop'
import { readonly } from 'vue';

export default {
  components: {
    CreateByValue,
    CreateByProduct,
    NoShop,
  },
  computed: {
    ...mapGetters(['shops']),
  },
  data() {
    return {
      type: '0',
    }
  },
  methods: {
    getCouponTypeOptions() {
      // return [
      //   { value: '0', label: this.$i18n.t('discountCoupon') },
      //   { value: '1', label: this.$i18n.t('redeemItem') },
      // ]
      return [
        { value: '0', label: this.$i18n.t('discountCoupon') },
      ]
    }
  }
}
</script>
